.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.scrollable-svg-container {
  width: 100%;
  max-width: 800px; /* Limit the maximum width to 800px */
  margin: 0 auto; /* Center the container horizontally */
  padding: 10px; /* Optional: Add padding around the SVG */
  overflow-x: hidden; /* Hide horizontal scrolling */
  overflow-y: auto; /* Enable vertical scrolling */
}

.quotesvg {

  width: 100%; /* Make SVG responsive */
  height: auto; /* Maintain aspect ratio */

}

.doc{
  width: 100%;
    height: 100vh;
}



@property --rotate {
  syntax: "<angle>";
  initial-value: 135deg;
  inherits: false;
}
.newsletter-card {
  background: #fff;
  width: 350px;
  height: 50px;
  position: relative;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  font-size: 1.5em;
  color: rgb(88 199 250 / 0%);
  cursor: pointer;
}
.newsletter-card::before {
  content: "";
  width: 358px;
  height: 58px;
  border-radius: 8px;
  background-image: linear-gradient(var(--rotate), #5ddcff, #3c67e3 43%, #4e00c2);
  position: absolute;
  z-index: -1;
  top: -4px;
  left: -4px;
  animation: spin 2.5s linear infinite;
}
@media only screen and (max-width: 358px) {
  .newsletter-card {
    background: #fff;
    width: 270px;
    height: 50px;
    position: relative;
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    text-align: center;
    
    font-size: 1.5em;
    color: rgb(88 199 250 / 0%);
    cursor: pointer;
  }
  .newsletter-card::before {
    content: "";
    width: 278px;
    height: 58px;
    border-radius: 8px;
    background-image: linear-gradient(var(--rotate), #5ddcff, #3c67e3 43%, #4e00c2);
    position: absolute;
    z-index: -1;
    top: -4px;
    left: -4px;
    animation: spin 2.5s linear infinite;
  }
}
  @keyframes spin {
  0% {
  --rotate: 0deg;
  }
  100% {
  --rotate: 360deg;
  }
  }
  .newsletter-input {
  background-color: white;
  border-radius: 8px 0 0 8px;
  border: none;
  padding: 0.5rem;
  width: 50%;
  font-size: 1rem;
  color: black;
  }
  .newsletter-input:focus {
  outline: none;
  }
  .newsletter-button {
  border: none;
  height: 50px;
  padding: 1rem;
  font-size: 0.7rem;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: auto;
  color: white;
  background-color: #202535;
  transition: 0.5s;
  background-size: 200% auto;
  box-shadow: 0 0 20px #eee;
  border-radius: 0 6px 6px 0;
  letter-spacing: 1px;
  }