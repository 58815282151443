.app {
    // background-color: #00FEFF;
    background-color: #0000;

    //color: blue;

    // .projects{
    //     background-color: #111;
    //     .sidebar{
    //         background-color: #111;
    //         color: white;
    //     }
    // }
}