.app{
    .projects{
        .header{
            background-color: white;
            width: 100%;
            position: sticky;
            top: 0px;
            z-index: 10;
            // align-self: flex-end;

        }
        
    }
}