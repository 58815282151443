.computer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    // width: 747px;
    // height: 605px;
    overflow: hidden;
}

@media screen and (min-width: 500px) {
    .computer {
        width: 430px;
        height: 355px;
    }
}
@media screen and (min-width: 590px) {
    .computer {
        width: 430px;
        height: 355px;
    }
}
@media screen and (min-width: 632px) {
    .computer {
        width: 490px;
        height: 405px;
    }
}
@media screen and (min-width: 674px) {
    .computer {
        width: 530px;
        height: 438px;
    }
}
@media screen and (min-width: 716px) {
    .computer {
        width: 560px;
        height: 463px;
    }
}
@media screen and (min-width: 758px) {
    .computer {
        width: 570px;
        height: 471px;
    }
}
@media screen and (min-width: 765px) {
    .computer {
        width: 570px;
        height: 471px;
    }
}
@media screen and (min-width: 768px) {
    .computer {
        width: 245px;
        height: 202px;
    }
}
@media screen and (min-width: 884px) {
    .computer {
        width: 280px;
        height: 231px;
    }
}
@media screen and (min-width: 1000px) {
    .computer {
        width: 380px;
        height: 314px;
    }
}
@media screen and (min-width: 1110px) {
    .computer {
        width: 510px;
        height: 421px;
    }
}
@media screen and (min-width: 1262px) {
    .computer {
        width: 530px;
        height: 438px;
    }
}
@media screen and (min-width: 1424px) {
    .computer {
        width: 650px;
        height: 537.5px;
    }
}
@media screen and (min-width: 1622px) {
    .computer {
        width: 720px;
        height: 595.5px;
     }
}




.computerBorder {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    // width: 747px;
    // height: 605px;
    overflow: hidden;
    z-index: 2;
    filter: drop-shadow(0 2px 10px rgba(0,0,0,0.2));
}
@media screen and (min-width: 500px) {
    .computerBorder {
        width: 430px;
        height: 355px;
    }
}
@media screen and (min-width: 590px) {
    .computerBorder {
        width: 430px;
        height: 355px;
    }
}
@media screen and (min-width: 632px) {
    .computerBorder {
        width: 490px;
        height: 405px;
    }
}
@media screen and (min-width: 674px) {
    .computerBorder {
        width: 530px;
        height: 438px;
    }
}
@media screen and (min-width: 716px) {
    .computerBorder {
        width: 560px;
        height: 463px;
    }
}
@media screen and (min-width: 758px) {
    .computerBorder {
        width: 570px;
        height: 471px;
    }
}
@media screen and (min-width: 765px) {
    .computerBorder {
        width: 570px;
        height: 471px;
    }
}
@media screen and (min-width: 768px) {
    .computerBorder {
        width: 245px;
        height: 202px;
    }
}
@media screen and (min-width: 884px) {
    .computerBorder {
        width: 280px;
        height: 231px;
    }
}
@media screen and (min-width: 1000px) {
    .computerBorder {
        width: 380px;
        height: 314px;
    }
}
@media screen and (min-width: 1110px) {
    .computerBorder {
        width: 510px;
        height: 421px;
    }
}
@media screen and (min-width: 1262px) {
    .computerBorder {
        width: 530px;
        height: 438px;
    }
}
@media screen and (min-width: 1424px) {
    .computerBorder {
        width: 650px;
        height: 537.5px;
    }
}
@media screen and (min-width: 1622px) {
    .computerBorder {
        width: 720px;
        height: 595.5px;
     }
}




.imgPc {
    position: absolute;
    transform: translateX(-50%);
    z-index: 1;
    // width: 680px;
    // height: 384px;
    top: 4.7%;
    left: 50%;
}

@media screen and (min-width: 500px) {
    .imgPc {
        width: 397px;
        height: 225px;
     }
}
@media screen and (min-width: 590px) {
    .imgPc {
        width: 398px;
        height: 225px;
     }
}
@media screen and (min-width: 632px) {
    .imgPc {
        width: 452px;
        height: 257px;
    }
}
@media screen and (min-width: 674px) {
    .imgPc {
        width: 488px;
        height: 277px;
    }
}
@media screen and (min-width: 716px) {
    .imgPc {
        width: 516px;
        height: 292px;
    }
}
@media screen and (min-width: 758px) {
    .imgPc {
        width: 526px;
        height: 298px;
    }
}
@media screen and (min-width: 765px) {
    .imgPc {
        width: 526px;
        height: 298px;
    }
}
@media screen and (min-width: 768px) {
    .imgPc {
        width: 228px;
        height: 128px;
    }
}
@media screen and (min-width: 884px) {
    .imgPc {
        width: 260px;
        height: 147px;
    }
}
@media screen and (min-width: 1000px) {
    .imgPc {
        width: 352px;
        height: 199px;
     }
}
@media screen and (min-width: 1110px) {
    .imgPc {
        width: 470px;
        height: 266px;
     }
}
@media screen and (min-width: 1262px) {
    .imgPc {
        width: 496px;
        height: 277px;
     }
}
@media screen and (min-width: 1424px) {
    .imgPc {
        width: 597px;
        height: 339px;
    }
}
@media screen and (min-width: 1622px) {
    .imgPc {
        // width: 680px; //kdenlive exact 1920x1080: 672x378
        // height: 384px;
        width: 662px;
        height: 372.375px;
     }
}

/////////////////////////////////////
//              PHONE
/////////////////////////////////////
.phone {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 371px;
    height: 750px;
    overflow: hidden;
}

.phoneBorder {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 371px;
    height: 750px;
    overflow: hidden;
    z-index: 2;
    filter: drop-shadow(0 -1px 3px rgba(0,0,0,0.2));
}

.imgPhone {
    position: absolute;
    transform: translateX(-50%);
    z-index: 1;
    width: 332px; //kdenlive exact 1440x3120: 336x728
    height: 720px;
    top: 2%;
    left: 50%;
}

.svg-container {
    position: absolute;
    width: 332px;
    height: 720px;
    overflow: hidden; /* Ensures SVG doesn't overflow */
    z-index: 1;
    top: 2%;
}

#frame1, #frame2, #frame3 {
    /*animation: moveRect 8s linear infinite; /* Adjust timing as needed */
	animation-name: moveRect;
	animation-duration: 4s;
	animation-fill-mode: both;
	animation-iteration-count: infinite;
	animation-direction: alternate;
	animation-timing-function: cubic-bezier(.74,.1,.26,.9);
}

@keyframes moveRect {
    0% { transform: translateX(0)}
    20% { transform: translateX(0)}
    30% { transform: translateX(-100%)}
    70% { transform: translateX(-100%)}
    80% { transform: translateX(-100%) translateY(-100%)}
    100% { transform: translateX(-100%) translateY(-100%)}
}