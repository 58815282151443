
$thesize: 8px;

@mixin cross($size: $thesize, $color: currentColor, $thickness: 1px) {
    margin: 0;
    padding: 0;
    border: 0;
    background: none;
    position: relative;
    width: $size;
    height: $size;
  
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: ($size - $thickness) / 2;
      left: 0;
      right: 0;
      height: $thickness;
      background: $color;
      border-radius: $thickness;
    }
  
    &:before {
      transform: rotate(45deg);
    }
  
    &:after {
      transform: rotate(-45deg);
    }
  
    span {
      display: block;
    }
  
  }
  
  
  // Example 1.
  .btn-close {
    padding: 0;
    background: RGBA(51, 50, 51, 0.7);
    border-radius: 50%;
    width: $thesize*3;
    height: $thesize*3;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 150ms;
    
    .icon-cross {
    //   @include cross($thesize * 2, #333333, $thesize/3.3333);   
        @include cross($thesize * 2, white, $thesize/5);   
    }
    
    &:hover,
    &:focus {
      transform: rotateZ(90deg);
      background: RGBA(51, 50, 51, 1);
    }
  
  }
  

  // // For screen readers.
  // .visually-hidden {
  //   position: absolute !important;
  //   clip: rect(1px, 1px, 1px, 1px);
  //   padding: 0 !important;
  //   border: 0 !important;
  //   height: 1px !important;
  //   width: 1px !important;
  //   overflow: hidden;
  // }
  


  .modal {
    background-color: rgba(0, 0, 0, 0.8);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }

  .modal-content {
    width: 60%;
    // min-height: 200px;
  }