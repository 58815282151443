.app.dark {
    background-color: #111;
    // color: rgb(156, 156, 156);
    color: blue;

    .projects{
        background-color: #111;
        .sidebar{
            background-color: #111;
            color: white;
        }
    }
}